<template>
  <v-card>
    <div class="dialog-loading">
      <v-progress-circular
        :size="48"
        indeterminate
        color="primary"
      />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'DialogLoading',
};
</script>

<style>
.dialog-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
</style>
