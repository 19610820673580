<template>
  <div class="snackbar">
    <transition-group
      appear
      tag="span"
      name="deck-snackbar--slide-from-right"
      class="snackbar__wrapper"
      @mouseenter="hasPausedMessages = true"
      @mouseleave="hasPausedMessages = false"
    >
      <deck-snackbar
        v-for="({ message, color, timeout, id }) in messages"
        :key="id"
        :text="message"
        :color="color"
        :timeout="hasPausedMessages ? -1 : timeout"
        :model-value="true"
        right
        top
        unanchored
        class="snackbar__item"
        attach=".snackbar"
        @update:model-value="!$event && hideMessage(id)"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapState } from '~/assets/javascript/modules/vuex';
import DeckSnackbar from '~/deck/snackbar';

export default {
  name: 'Snackbar',
  components: {
    DeckSnackbar,
  },
  setup() {
    return {
      ...mapState('snackbar', ['messages']),
      ...mapActions('snackbar', ['hideMessage']),
    };
  },
  data() {
    return {
      hasPausedMessages: false,
    };
  },
  watch: {
    messages: {
      async handler() {
        await nextTick();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.snackbar {
  --margin: 16px;
  --gap: 8px;

  position: fixed;
  margin: var(--margin);
  height: calc(100vh - calc(var(--margin) * 2));
  width: calc(100vw - calc(var(--margin) * 2));
  pointer-events: none;
  z-index: 1000;
}

.snackbar__wrapper {
  pointer-events: all;
  position: absolute;
  right: 0;
}

.snackbar__item {
  position: relative !important;

  &.deck-snackbar--slide-from-right-leave-active {
    position: absolute !important; // for smooth transition when removing a message from the array
    z-index: -1;

    &, .v-snackbar__wrapper {
      pointer-events: none !important; // to allow clicks on the next message while the current one is still fading out
    }
  }
}
</style>
